<template>
  <BaseFacility>
    <template v-slot:title>
        <h1 class="py-4 page-title-main">
        {{ $t("routeTitles.facility.facility") }}
      </h1>
    </template>
    <template v-slot:default>
      <div class="facility-settings-table-container">
        <p class="heading mt-5 mb-2 page-title-main">
          {{ $t('facility.settings.facilityList') }}
        </p>
        <v-simple-table
          class="table-custom"
          disable-pagination
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th>{{ $t('facility.settings.propertyID') }}</th>
              <th>{{ $t('facility.settings.propertyName') }}</th>
              <th></th>
              <th>{{ $t('facility.settings.facilityEditing') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in facilityList" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <v-btn
                  :disabled="!checkPerUser"
                  small
                  class="btn--primary"
                  @click="updateSelectedFacility(item.id, item.name)"
                >
                  {{ $t('buttons.roomTypeSetting') }}
                </v-btn>
              </td>
              <td>
                <v-btn
                  :disabled="!checkPerUser"
                  class="btn-edit"
                  :to="'/facility/settings/detail?id=' + item.id + '&name=' + item.name"
                  outlined
                  small
                  target="_blank"
                  color="var(--text__primary)"
                >{{ $t('buttons.edit') }}
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-right mt-5">
          <v-btn class="btn--bt__blue btn-create-new" :disabled="!checkPerUser" @click="openDialogEdit = true" style="width: 92px;height: 32px!important;">
            新規施設作成
          </v-btn>
        </div>
      </div>

      <base-dialog
        width="1000"
        :visible="openDialogEdit"
        @close="openDialogEdit = false"
        title='新規施設作成'
        isGrayDialog
      >
        <template v-slot:content>
          <v-form
            ref="form"
          >
            <div class="d-flex mt-5">
              <label class="t-label custom-title-dialog">
                {{ $t('facility.settings.facilityType') }}
              </label>
              <v-select
                :items="listFacilityType"
                v-model="facilityTypeSelected"
                small
                dense
                outlined
                color="var(--text__gray)"
                hide-details
              ></v-select>
              <div class="text--small ml-4" style="color: #666666; font-size: 13px !important">
                {{ $t('facility.settings.facilityTypeNote') }}
                <br/>
                {{ $t('facility.settings.facilityTypeNoteDetail') }}
              </div>
            </div>
            <div class="d-flex mt-3">
              <label class="t-label custom-title-dialog">
                {{ $t('facility.settings.facilityNameManagement') }}
              </label>
              <v-text-field
                dense
                outlined
                v-model="facilityName"
                required
                :rules="rulesFacility.name"
              ></v-text-field>
            </div>
          </v-form>
        </template>

        <template v-slot:footer>
          <div class="text-right mt-5">
            <v-btn
              :disabled="!checkPerUser"
              style="font-size: 17px; font-weight: bold"
              large
              class="btn--pink mr-4"
              @click="openDialogEdit = false"
            >{{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!checkPerUser"
              style="font-size: 17px; font-weight: bold"
              large
              color="#004F8B"
              @click="createNewFacility"
            ><span style="color: #ffffff">{{ $t('buttons.saveAndCreate') }}</span>
            </v-btn>
          </div>
        </template>
      </base-dialog>
    </template>
  </BaseFacility>
</template>

<script>
import BaseFacility from '@/components/Facility/BaseFacility'
import BaseDialog from '../../../../components/Dialog/BaseDialog.vue'
import { FACILITY_TYPE_ENUM } from '@/constants/enum'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { mapMutations, mapActions } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'FacilitySettings',
  apollo: {
    facilityList: gql`${FACILITY_LIST}`
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      // Initialize your apollo data
      facilityList: [],
      // ============ DIALOG ============
      openDialogEdit: false,
      listFacilityType: [],
      facilityTypeSelected: Object.keys(FACILITY_TYPE_ENUM)[0],
      facilityName: '',
      facilitySelect: '',
      rulesFacility: {
        name: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 256 || this.$t('rules.isInvalid')
        ]
      }
    }
  },
  created () {
    this.listFacilityType = Object.keys(FACILITY_TYPE_ENUM)
  },
  methods: {
    ...mapMutations({
      setFacilitySettings: 'setFacilitySettings',
      setSelectedFacility: 'setSelectedFacility'
    }),
    ...mapActions({
      actionCreate: 'createFacility',
      actionUpdateSelectedFacility: 'updateSelectedFacility'
    }),
    createNewFacility () {
      if (this.$refs.form.validate()) {
        if (this.facilityTypeSelected === 'TVS施設') {
          this.facilitySelect = 'TVS'
        } else if (this.facilityTypeSelected === 'ホテルコンバージョン施設') {
          this.facilitySelect = 'CNV'
        } else {
          this.facilitySelect = 'PT'
        }
        const facilitySettings = {
          name: this.facilityName,
          type: this.facilitySelect
        }
        this.setFacilitySettings({ facilitySettings: facilitySettings })
        this.actionCreate()
        this.openDialogEdit = false
        this.facilityName = null
      }
    },
    updateSelectedFacility (id, facilityName) {
      this.actionUpdateSelectedFacility(facilityName)
      this.$router.push({ path: `/facility/settings/room-type?id=${id}` })
    }
  },
  components: {
    BaseFacility,
    BaseDialog
  }
}
</script>

<style lang="scss" scoped>
  .facility-settings-table-container {
    max-width: 1200px;
  }
  .heading {
    font-weight: 500;
    font-size: 16px;
  }

  .t-label {
    width: 110px;
    margin-right: 15px;
    flex-shrink: 0;
    text-align: right;
  }

  .btn-edit {
    font-size: 10px;
  }

  ::v-deep {
    .v-data-table {
      tbody tr td {
        text-align: center;
      }
    }

    .v-input {
      max-width: 240px;
      width: 240px;
    }

    .table-custom {
      thead tr th, tbody tr td {
        text-align: center !important;
      }
    }
  .v-select__selection--comma {
    font-size: 16px;
    color: #666666;
    width: 140px !important;
  }
  svg {
    height: 40px;
    width: 40px;
    color: #424242;
  }

  }
  .btn-create-new {
    font-size: 12px;
    // width: 92px !important;
    height: 32px !important;
  }
  .custom-title-dialog {
    font-size: 16px;
    color: #666666;
    width: 140px !important;
  }
</style>
